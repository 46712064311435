import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Home from "./components/Home/Home";
import ProductList from "./components/ProductList/ProductList";
import CartList from "./components/CartList/CartList";
import MyProfile from "./components/MyProfile/MyProfile";
import CheckOut from "./components/CheckOut/CheckOut";
import OrderConfirm from "./components/OrderConfirm/OrderConfirm";
import Item from "./components/SingleItem/Item";
import OrderDetail from "./components/OrderDetail/OrderDetail";
import SearchList from "./components/SearchList/SearchList";
import NavBar from "./components/NavBar/NavBar";
import ProductSelection from "./components/ProductSelection/ProductSelection";
import SubLevel from "./components/SubLevel/SubLevel";
import OrderList from "./components/OrderList/OrderList";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<Register />} path="/register" />
          <Route element={<Home />} path="/" />
          <Route element={<ProductList />} path="/products" />
          <Route element={<Item />} path="/item" />
          <Route element={<CartList />} path="/cart" />
          <Route element={<MyProfile />} path="/myprofile" />
          <Route element={<CheckOut />} path="/checkout" />
          <Route element={<OrderConfirm />} path="/order/confirm" />
          <Route element={<OrderDetail />} path="/myorders" />
          <Route element={<NavBar />} path="/navbar" />
          <Route element={<SearchList />} path="/search/products" />
          <Route element={<ProductSelection />} path="/products/selection" />
          <Route element={<SubLevel />} path="/sublevel" />
          <Route element={< OrderList/>} path="/orderlist" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
