import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import style from "./Item.module.css";
// import nike from "../../assets/nk.jpg";
// import nk from "../../assets/n.jpg";
// import ad from "../../assets/ad.png";
// import pu from "../../assets/pu.jpeg";
import placeholder from "../../assets/placeholder.jpg";
import { HiStar } from "react-icons/hi";
import { FiShoppingCart } from "react-icons/fi";
// import { FaShoppingCart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const Item = () => {
  const [count, setCount] = useState(0);
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const single_item = state.single_product;
  // console.log(single_item);
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const increment = () => {
    if (count < 100) {
      setCount(Number(count) + 1);
    }
  };
  const handleChange = (e) => {
    setCount(e.target.value);
  };

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.cart_page}>
          <div className={style.page_top}>
            <div className={style.cart_image}>
              {/* <img src={single_item.URL} alt="" /> */}
              {single_item.URL !== "" ? (
                            <img src={single_item.URL} alt="" />
                          ) : (
                            <img src={placeholder} alt="" />
                          )}
            </div>
            <div className={style.cart_product}>
              <div className={style.product_name}>
                <h1>{single_item.product_name}</h1>
                {/* <h2>Men's Shoe</h2> */}
              </div>
              <div className={style.detail}>
                <div className={style.rating}>
                  <HiStar />
                  <HiStar />
                  <HiStar />
                  <HiStar />
                  <HiStar />
                </div>
                <div className={style.review}>
                  <p>(105 review)</p>
                </div>
              </div>
              <div className={style.description}>
                <div className={style.quantity}>
                  <button onClick={decrement}>-</button>
                  <input
                    type="text"
                    name=""
                    id=""
                    value={count}
                    onChange={handleChange}
                  />
                  <button onClick={increment}>+</button>
                </div>
                <div className={style.price}>
                  <p>Rs.{single_item.MRP}</p>
                </div>
              </div>

              <div className={style.size_chart}>
                <div className={style.chart_head}>
                  <h1>Size Chart</h1>
                </div>
                <div className={style.chart_button}>
                  <button>5</button>
                  <button>6</button>
                  <button>7</button>
                  <button>8</button>
                  <button>9</button>
                  <button>10</button>
                </div>
              </div>
              <div className={style.order}>
                <div className={style.buy}>
                  <button>Buy Now</button>
                </div>
                <div className={style.cart}>
                  <div className={style.cart_icon}>
                    <FiShoppingCart />
                  </div>
                  <div className={style.cart_text}>
                    <button> Add to cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={style.page_bottom}>
            <h1>Related Products</h1>
            <div className={style.related_product}>
              <div className={style.card}>
                <div className={style.card_head}>
                  <img src={nk} alt="" />
                </div>
                <div className={style.card_body}>
                  <div className={style.card_left}>
                    <div className={style.card_name}>
                      <p>Nike</p>
                    </div>
                    <div className={style.card_price}>
                      <p>$200</p>
                    </div>
                  </div>
                  <div className={style.card_right}>
                    <FaShoppingCart />
                  </div>
                </div>
              </div>

              <div className={style.card}>
                <div className={style.card_head}>
                  <img src={ad} alt="" />
                </div>
                <div className={style.card_body}>
                  <div className={style.card_left}>
                    <div className={style.card_name}>
                      <p>Adidas</p>
                    </div>
                    <div className={style.card_price}>
                      <p>$300</p>
                    </div>
                  </div>
                  <div className={style.card_right}>
                    <FaShoppingCart />
                  </div>
                </div>
              </div>
              <div className={style.card}>
                <div className={style.card_head}>
                  <img src={pu} alt="" />
                </div>
                <div className={style.card_body}>
                  <div className={style.card_left}>
                    <div className={style.card_name}>
                      <p>Puma</p>
                    </div>
                    <div className={style.card_price}>
                      <p>$350</p>
                    </div>
                  </div>
                  <div className={style.card_right}>
                    <FaShoppingCart />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Item;
