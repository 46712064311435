import { SEARCH_FAIL, SEARCH_REQUEST, SEARCH_SUCCESS } from "../Constants/Search";


const org_id=JSON.parse(localStorage.getItem("orgId"))
const intOrg_id=parseInt(org_id)
const BaseUrl = "https://cpanel.q-hawk.com";
export const Search_Items = (props) => async (dispatch)=> {
    console.log(props)
    try {
        dispatch({
            type: SEARCH_REQUEST,
          }); 
          await fetch(`${BaseUrl}//product/SearchProductEcom`,{
            method: "post",
            body: JSON.stringify({
                organization_id:intOrg_id ,
                search_keywords:props,
                lower_limit: 1,
                page_size: 10
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => res.json())
          .then((data) => {
            dispatch({
              type: SEARCH_SUCCESS,
              payload: data,
            });
            console.log(data);
          });
    } catch (error) {
        dispatch({
            type: SEARCH_FAIL,
            payload: error.message,
          });
          console.log(error);
          console.log(error.message);
        }
    
};