import {
  SEARCH_DELETE,
  SEARCH_FAIL,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
} from "../Constants/Search";

export const SearchReducer = (state = { search: {} }, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        loading: true,
      };
    case SEARCH_SUCCESS:
      return {
        loading: false,
        search: action.payload,
      };
    case SEARCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SEARCH_DELETE:
      return {
        loading: false,
        search: [],
      };

    default:
      return {
        ...state,
      };
  }
};
