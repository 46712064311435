import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../Constants/User";

const BaseUrl = "https://cpanel.q-hawk.com"; //base url
export const login = (props) => async (dispatch) => {
  //using props for recive data that is shared from login,
  //remember is is for adding local storage
  // console.log(props);
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    await fetch(`${BaseUrl}/user/loginFromE_Qhawk`, {
      //end point
      method: "post", //post method - set method,body,headers
      body: JSON.stringify({
        Email: props.email,
        Password: props.password,
      }),

      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json()) //response assigning to variable data
      .then((data) => {
        console.log(data);
        if (data !=0) {
          localStorage.setItem("orgId", JSON.stringify(data[0].organization_id)); //local storage assigning using setItem
          localStorage.setItem("userId", JSON.stringify(data[0].UserID));
          localStorage.setItem("customerId",JSON.stringify(data[0].linked_customer_id));
          if(data[0].UserID !="" || data[0].UserID !=null || data[0].UserID !=undefined){
            window.location.href="/";
          }
          else{
            window.location.href="/login";
  
          }
        }

        if(data ===0 || data ===null || data ===undefined){
          alert("Login Failed ! please enter the correct email or password")
        }
      
        const locId = localStorage.getItem("orgId");
        console.log(locId);
        dispatch({
          //redux dispatch success
          type: LOGIN_SUCCESS,
          payload: data, //data is present on payload
        });
        console.log(data);
        // window.location.reload()
      });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL, //error part
      payload: error.message,
    });
    console.log(error);
    console.log(error.message);
  }
};
