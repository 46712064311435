import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import style from "./Home.module.css";
import ad from "../../assets/k.png";
import placeholder from "../../assets/placeholder.jpg";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import PaginationPage from "../Pagination/PaginationPage";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [product, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 12;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = product.slice(startIndex, endIndex);

  async function fetchProducts(level_id) {
    const org_id = JSON.parse(localStorage.getItem("orgId"));
    const intOrg_id = parseInt(org_id);
    console.log(intOrg_id);
    console.log(org_id, "orgid");
    await fetch(
      "https://cpanel.q-hawk.com/product/GetProductLevelWithNextSubLevelEcommerce",
      {
        method: "POST",
        body: JSON.stringify({
          organization_id: intOrg_id,
          product_level_id: level_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "catogry");
        if (level_id === 0) {
          setProducts(data);
          setIsLoading(false);
        } else {
          if (data === 0) {
            navigate("/products", { state: { level: level_id } });
            console.log("end product", data);
          } else {
            console.log("else", data);
            navigate("/sublevel", { state: { sublevel: data } });
          }
        }
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProducts(0);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <NavBar />
      {isLoading ? (
        <Loader />
      ) : (
        <div className={style.container}>
          <div className={style.home}>
            <div className={style.add}>
              <div className={style.ad_left}>
                <div className={style.left_write}>
                  <h1>Are you ready to </h1>
                  <h2>lead the way</h2>
                  <p>Luxury meets ultimate sitting comfort</p>
                </div>
                <div className={style.left_bottom}>
                  <button>Discover</button>

                  <HiOutlineArrowRight />
                </div>
              </div>
              <div className={style.ad_right}>
                <img src={ad} alt="" />
              </div>
            </div>
            <div className={style.brand}>
              <h2>Top Brands</h2>
              <div className={style.brand_cards}>
                {currentData &&
                  currentData.length > 0 &&
                  currentData !== undefined &&
                  currentData
                    .filter((item) => item.parent === 0)
                    .map((item2) => {
                      console.log(item2);

                      return (
                        <div
                          className={style.card_page}
                          onClick={() => {
                            fetchProducts(item2.product_level_id);
                            setIsLoading(true)
                          }}
                        >
                          <div className={style.card}>
                            {item2.image_url !==
                            "https://cpanel.q-hawk.com/adminPanel/" ? (
                              <img src={item2.image_url} alt="" />
                            ) : (
                              <img src={placeholder} alt="" />
                            )}
                          </div>
                          <h1>{item2.level_name}</h1>
                        </div>
                      );
                    })}
              </div>
              <PaginationPage
                totalCount={product.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
