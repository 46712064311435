import React from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationPage = ({ totalCount, currentPage, onPageChange }) => {

    const itemsPerPage = 10;
    const handlePageChange = (event, value) => {
        onPageChange(value);
    };

    return (
        <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Pagination
                count={Math.ceil(totalCount / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                color="secondary"
                sx={{
                    '& .MuiPaginationItem-root': {
                        color: '#286091',
                        fontSize: '1.5rem'
                    },
                    '& .Mui-selected': {
                        backgroundColor: '#007bff',
                        color: '#fff',
                        fontSize: '1.5rem'
                    },
                }}
            />
        </Stack>
    )
}

export default PaginationPage
