import React from "react";
import style from "./SearchList.module.css";
import NavBar from "../NavBar/NavBar";
import { FaShoppingCart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import placeholder from "../../assets/placeholder.jpg";

const SearchList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const searched_item = state.search;
  console.log(searched_item);
  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.search_page}>
          <div className={style.product_firstrow}>
            <div className={style.product_card}>
              <div className={style.product_image}>
                {searched_item.URL !== "" ? (
                  <img src={searched_item.URL} alt="" />
                ) : (
                  <img src={placeholder} alt="" />
                )}
              </div>
              <div className={style.card_body}>
                <div className={style.product_description}>
                  <div
                    className={style.product_name}
                    // onClick={() => {
                    //   navigate("/item", {
                    //     state: { single_product: searched_item },
                    //   });
                    // }}
                  >
                    <p>{searched_item.product_name}</p>
                  </div>
                  <div className={style.product_rate}>
                    <p>{searched_item.MRP}</p>
                  </div>
                </div>
                <div className={style.cart}>
                  <FaShoppingCart
                    onClick={() => {
                      navigate("/products/selection", {
                        state: { product_selection: searched_item },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchList;
