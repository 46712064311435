import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { UserReducer } from "./Reducer/User";
import { ProductReducer } from "./Reducer/Products";
import { SearchReducer } from "./Reducer/Search";

const reducers = combineReducers({
    user:UserReducer,
    product:ProductReducer,
    search:SearchReducer
});
const middleware=[thunk]
const InitialState={}
const Store=createStore(reducers,InitialState,composeWithDevTools(applyMiddleware(...middleware)))

export default Store; 