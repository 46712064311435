import React, { useState, useEffect } from "react";
import style from "./CartList.module.css";
import NavBar from "../NavBar/NavBar";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const CartList = () => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const cartItems =
      localStorage.getItem("cart") &&
      localStorage.getItem("cart") &&
      JSON.parse(localStorage.getItem("cart"));
    if (cartItems) {
      setCart(cartItems);
    }
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [cart]);

  const calculateTotal = () => {
    const total = 0;
    console.log("total is", total);

    const totalAmount =
      cart !== undefined &&
      cart !== null &&
      cart.length > 0 &&
      cart.reduce((total, data) => total + parseInt(data.result), 0);
    let totalQuantity = 0;
    cart !== undefined &&
      cart !== null &&
      cart.length > 0 &&
      cart.forEach((item) => {
        let initialQuantity = 0;
        console.log(item);
        item.varients !== undefined &&
          item.varients !== null &&
          item.varients.length > 0 &&
          item.varients.forEach((data) => {
            console.log(data);
            initialQuantity = initialQuantity + parseInt(data.quantity);
          });
        totalQuantity = totalQuantity + initialQuantity;
        if (
          item.varients !== undefined &&
          item.varients !== null &&
          item.varients.length > 0 &&
          item.type === "Special"
        ) {
          item.quan = initialQuantity;
        } else if (
          item.varients !== undefined &&
          item.varients !== null &&
          item.varients.length > 0 &&
          item.type === "Standard"
        ) {
          item.quan = item.quan;
        }
      });
    console.log("total amount is", totalAmount);
    console.log("totalQuantity is", totalQuantity);
    setTotal(totalAmount);
  };

  const checkout = () => {
    if (cart.length === 0) {
      // Show a popup or display a message indicating that the cart is empty
      alert("Cart is empty. Please add items to the cart.");
    } else {
      localStorage.setItem("checkout", JSON.stringify(total));
      navigate("/checkout");
    }
  };
  // useEffect(()=>{
  //   console.log(data)
  // },[data])

  const addItem = () => {
    navigate("/");
  };

  function removeItem(index) {
    console.log(index, "index");
    let updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    // window.location.reload()
  }

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.cart_page}>
          <div className={style.cart_list}>
            <div className={style.head}>
              <div className={style.head_left}>
                <h1>Cart</h1>
              </div>
              {/* <div className={style.head_right}>
                <div className={style.remove_icon}>
                  <RiDeleteBin5Line />
                </div>
                <div className={style.remove}>
                  <button>Remove</button>
                </div>
              </div> */}
            </div>
            <div className={style.description_head}>
              <div className={style.des_left}>
                {/* <div className={style.left_check}>
                  <input type="checkbox" name="" id="" />
                </div> */}
                <div className={style.left_product}>
                  <p>PRODUCT</p>
                </div>
              </div>
              <div className={style.des_right}>
                <div className={style.right_quantity}>
                  <p>QUANTITY</p>
                </div>
                <div className={style.right_price}>
                  <p>PRICE</p>
                </div>
              </div>
            </div>
            {cart !== undefined &&
              cart !== null &&
              cart.length > 0 &&
              cart.map((data, index) => {
                {
                  console.log(data);
                  /* setTotal(total+data.data.selling_price) */
                }
                return (
                  <div className={style.product_one}>
                    <div className={style.product_left}>
                      {/* <div className={style.product_check}>
                      <input type="checkbox" name="" id="" />
                          </div> */}
                      <div className={style.product_card}>
                        <div className={style.card_left}>
                          <img src={data.data.URL} alt="" />
                        </div>
                        <div className={style.card_right}>
                          {/* <div className={style.cardright_head}> */}
                          <h1>{data.data.product_name}</h1>
                          <p>Size : {data.data.size}</p>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className={style.product_center}>
                      <div className={style.quantity}>
                        {/* <button>-</button> */}
                        <input type="text" value={data.quan} readOnly />
                        {/* <button>+</button> */}
                      </div>
                      <div className={style.quantity_delete}>
                        <div className={style.delete_icon}>
                          <RiDeleteBin5Line onClick={() => removeItem(index)} />
                        </div>
                        <div className={style.delete}>
                          <p>Remove</p>
                        </div>
                      </div>
                    </div>
                    <div className={style.product_right}>
                      <p>
                        Rs:
                        {data.type === "Special"
                          ? data.data.MRP * data.quan
                          : data.data.price * data.quan}
                      </p>
                    </div>
                  </div>
                );
              })}
            <div className={style.add_item}>
              <button onClick={addItem}>Add Item</button>
            </div>
          </div>
          {/* console.log(total) */}
          <div className={style.confirmation}>
            <div className={style.confirmation_dialogue}>
              <div className={style.dialogue_head}>
                <div className={style.subtottal}>
                  <p>Subtotal</p>
                  <p>Rs:{total}</p>
                </div>
                <div className={style.discount}>
                  <p>Discount</p>
                  <p>0.0</p>
                </div>
              </div>
              <div className={style.dialogue_body}>
                <div className={style.grandtotal}>
                  <p>Grand total</p>
                  <p>Rs:{total}</p>
                </div>
                <div className={style.checkout}>
                  <button onClick={checkout}>Checkout Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartList;
