import React from "react";
import NavBar from "../NavBar/NavBar";
import style from "./OrderConfirm.module.css";
import { BsCheckCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
const OrderConfirm = () => {
  const newDate = new Date();
  const dayData = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const day = dayData[newDate.getDay()];
  const monthData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthData[newDate.getMonth()];
  const date = newDate.getDate();
  const total =
    localStorage.getItem("checkout") &&
    JSON.parse(localStorage.getItem("checkout"));
  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.confirmation_page}>
          <div className={style.confirmation_head}>
            <div className={style.head_up}>
              <BsCheckCircle />
            </div>
            <div className={style.head_bottom}>
              <p>Your order placed successfully !</p>
            </div>
            <p>The order confirmation has sent.</p>
          </div>
          <div className={style.confirmation_body}>
            <div className={style.body_up}>
              <h2>Transaction Date</h2>
              <p>
                {day}, {month} {date}
              </p>
            </div>
            {/* <div className={style.body_bottom}>
              <h2>Payment Status</h2>
              <p>Success</p>
            </div> */}
            <div className={style.body_bottom}>
              <h2>Order Status</h2>
              <p>Pending</p>
            </div>
          </div>
          <div className={style.order_detail}>
            <h2>Your Order</h2>
            <div className={style.order_rate}>
              <div className={style.order_catogory}>
                <p>Subtotal</p>
              </div>
              <div className={style.order_price}>
                <p>Rs.{total}</p>
              </div>
            </div>
            {/* <div className={style.order_rate}>
              <div className={style.order_left}>
                <p>Shippment cost</p>
              </div>
              <div className={style.order_right}>
                <p>-Rs.0.00</p>
              </div>
            </div> */}
            <div className={style.order_rate}>
              <div className={style.total}>
                <p>Grand total</p>
              </div>
              <div className={style.total_price}>
                <p>Rs{total}</p>
              </div>
            </div>
          </div>
          <div className={style.order_button}>
            <Link to="/orderlist">
              <button>Order Details</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
