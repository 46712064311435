import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import style from "./ProductList.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import placeholder from "../../assets/placeholder.jpg";
import Loader from "../../Loader/Loader";
import PaginationPage from "../Pagination/PaginationPage";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CgShoppingCart } from "react-icons/cg";

const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [counts, setCounts] = useState(new Array(productList.length).fill(1));
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const levelId = state.level;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleError = () => {
    setClose(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setClose(false);
  };

  const itemsPerPage = 10;
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = productList.slice(startIndex, endIndex);

  const incrementProduct = (index) => {
    setCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = (prevCounts[index] || 0) + 1;
      return newCounts;
    });
  };

  const decrementProduct = (index) => {
    setCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = Math.max((prevCounts[index] || 0) - 1, 0);
      return newCounts;
    });
  };

  const handleChangeProduct = (index, e) => {
    const value = e.target.value;
    setCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] =
        value === "" ? 1 : Math.max(parseInt(value, 10) || 1, 1);
      return newCounts;
    });
  };

  const addCart = async (
    productId,
    item,
    size,
    productDetail,
    uo,
    price,
    variants,
    packagedItem,
    index
  ) => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const result = parseInt(price) * parseInt(counts[index]);
    const singleCart = {
      product_id: productId,
      item: item,
      quan: counts[index],
      size: size,
      result: result,
      data: productDetail,
      uo: uo,
      price: price,
      type: "",
      varients: variants,
      is_packaged_item: packagedItem,
    };
    existingCart.push(singleCart);
    localStorage.setItem("cart", JSON.stringify(existingCart));
    const updatedCounts = [...counts];
    updatedCounts[index] = 0;
    setCounts(updatedCounts);
    handleClick();
  };

  async function endProducts() {
    const org_id = JSON.parse(localStorage.getItem("orgId"));
    const intOrg_id = parseInt(org_id);
    console.log(intOrg_id);
    await fetch(
      "https://cpanel.q-hawk.com/product/GetAllProductForAdminWithFilter",
      {
        method: "POST",
        body: JSON.stringify({
          organization_id: intOrg_id,
          product_level_id: levelId,
          lower_limit: 1,
          page_size: 10,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "endproduct");
        setProductList(data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    endProducts();
  }, [levelId]);

  return (
    <div>
      <NavBar />
      {isLoading ? (
        <Loader />
      ) : (
        <div className={style.container}>
          <div className={style.first_product}>
            <div className={style.head}>
              <h1>Our Products</h1>
            </div>
            <div className={style.productSection}>
              <div className={style.product_firstrow}>
                {currentData.length > 0 &&
                  currentData.map((all_product, index) => {
                    return (
                      <div className={style.product_card}>
                        <div className={style.cardHead}>
                          <div className={style.product_image}>
                            {all_product.URL !== "" ? (
                              <img src={all_product.URL} alt="" />
                            ) : (
                              <img src={placeholder} alt="" />
                            )}
                          </div>
                          <div className={style.countSelection}>
                            <button onClick={() => decrementProduct(index)}>
                              -
                            </button>
                            <input
                              type="text"
                              value={
                                counts[index] !== undefined
                                  ? counts[index].toString()
                                  : "0"
                              }
                              onChange={(e) => handleChangeProduct(index, e)}
                            />
                            <button onClick={() => incrementProduct(index)}>
                              +
                            </button>
                          </div>
                        </div>
                        <div className={style.card_body}>
                          <div className={style.product_description}>
                            <div
                              className={style.product_name}
                              // onClick={() => {
                              //   navigate("/item", {
                              //     state: { single_product: all_product },
                              //   });
                              // }}
                            >
                              <p>{all_product.product_name}</p>
                            </div>
                            <div className={style.product_rate}>
                              <p>{all_product.MRP}</p>
                            </div>
                          </div>
                          <div
                            className={style.cart}
                            onClick={() => {
                              navigate("/products/selection", {
                                state: { product_selection: all_product },
                              });
                            }}
                          >
                            <input type="checkbox" name="" id="" />
                          </div>
                        </div>

                        <div className={style.cartButton}>
                          <button
                            onClick={() => {
                              if (
                                counts[index] === undefined ||
                                counts[index] === ""
                              ) {
                                handleError();
                              } else {
                                addCart(
                                  all_product.product_id,
                                  all_product.product_name,
                                  all_product.size,
                                  all_product,
                                  all_product.UOM,
                                  all_product.price,
                                  all_product.varients,
                                  all_product.is_packaged_item,
                                  index
                                );
                              }
                            }}
                          >
                            Add To Cart
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <PaginationPage
                totalCount={productList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={1000}
            onClose={handleClose}
            spacing={2}
          >
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "8px" }}>
                  Successfully added to cart
                </span>
                <CgShoppingCart
                  onClick={() => {
                    navigate("/cart");
                  }}
                  style={{ fontSize: "1.25rem", cursor: "pointer" }}
                />
              </div>
            </Alert>
          </Snackbar>

          <Snackbar
            open={close}
            autoHideDuration={2000}
            onClose={handleErrorClose}
            spacing={2}
          >
            <Alert
              variant="filled"
              onClose={handleErrorClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please select quantity
            </Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default ProductList;
