import React, { useState } from "react";
import style from "./Register.module.css";
import Shoe from "../../assets/blue1.jpg";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { TbBrandSamsungpass } from "react-icons/tb";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    // const [allEntry, setAllEntry] = useState([]);
    const handleSubmit = (e) => {
      e.preventDefault();
    //   const newEntry = { email: email, password: password };
    //   setAllEntry([...allEntry,newEntry]);
      console.log(email);
      console.log(password);
    };
  return (
    <div>
      <div className={style.container}>
        <div className={style.right}>
          <div className={style.form_border}>
            <h1>Welcome !</h1>
            <p>please enter your details</p>
            <form action="" className={style.register_form} onSubmit={handleSubmit}>
              <div className={style.email}>
                <input
                    value={email}
                  type="email"
                  name=""
                  id=""
                  placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <IconContext.Provider value={{ color: "#3a71f2" }}>
                  <MdOutlineAlternateEmail />
                </IconContext.Provider>
              </div>
              <div className={style.password}>
                <input
                    value={password}
                  type="password"
                  name=""
                  id=""
                  placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <IconContext.Provider value={{ color: "#3a71f2" }}>
                  <TbBrandSamsungpass />
                </IconContext.Provider>
              </div>
              <div className={style.password}>
                <input
                    value={rePassword}
                  type="password"
                  name=""
                  id=""
                  placeholder="Retype password"
                    onChange={(e) => setRePassword(e.target.value)}
                />
                <IconContext.Provider value={{ color: "#3a71f2" }}>
                  <TbBrandSamsungpass />
                </IconContext.Provider>
              </div>

              <button type="submit">Login</button>
            </form>
          </div>
          <div className={style.register}>
            <p>Already have an account ?</p>
            <Link to="/login">
            <button>Sign In</button>
            </Link>
          </div>
        </div>
        <div className={style.left}>
          <img src={Shoe} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Register;
