import React, { useEffect, useState } from "react";
import style from "./CheckOut.module.css";
import NavBar from "../NavBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";

const CheckOut = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [pin, setPin] = useState("");
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");

  const navigate = useNavigate();

  const org_id = JSON.parse(localStorage.getItem("orgId"));
  const intOrg_id = parseInt(org_id);
  const grant_total = JSON.parse(localStorage.getItem("checkout"));
  const total_amount = parseInt(grant_total);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const intUser_id = parseInt(userId);
  const coustomerid = JSON.parse(localStorage.getItem("customerId"));
  const intCoustId = parseInt(coustomerid);
  const cartItems =
    localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"));
  // const location = useLocation();
  // const state = location.state;
  // console.log(state,"checkoutitems")
  const newDate = new Date();
  const currentDate = newDate.getDate();
  const currentYear = newDate.getFullYear();
  const currentMonth = newDate.getMonth();
  useEffect(() => {
    cartItems !== undefined &&
      cartItems !== null &&
      cartItems.length > 0 &&
      cartItems.map((item) => {
        console.log(item);
        // const CartCopy = item
        // const getIndex = CartCopy.findIndex((item2)=>{
        // })
        const CartCopy = item;
        CartCopy.data.Qty = item.quan;
        setData((data) => [...data, item.data]);
      });
    setDate(`${currentDate}-${currentMonth}-${currentYear}`);
  }, []);
  useEffect(() => {
    console.log(data, "data of the product");
  }, [data]);
  const orderConfirm = (e) => {
    e.preventDefault();

    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      address === "" ||
      city === "" ||
      region === "" ||
      pin === ""
    ) {
      // Indicate that a field is empty (you can show an error message or handle it as needed)
      console.log("Some fields are empty");
      alert("Fields are empty");
      return;
    }

    if (!/^\d+$/.test(phone)) {
      // Indicate that phone number is invalid (you can show an error message or handle it as needed)
      console.log("Phone number is invalid");
      alert("Enter a valid phone number");
      return;
    }

    // Validate email
    if (!/\S+@\S+\.\S+/.test(email)) {
      // Indicate that email is invalid (you can show an error message or handle it as needed)
      console.log("Email is invalid");
      alert("Enter a valid Email");
      return;
    }

    order(intOrg_id, intUser_id, total_amount);
    async function order() {
      const Body = {
        CustomerId: intCoustId,
        CustomerName: name,
        email: email,
        phoneNumber: phone,
        billing_address_line1: address,
        billing_city: city,
        billing_state: region,
        billing_zipcode: pin,
        organizationId: intOrg_id,
        UserId: intUser_id,
        Total: total_amount,
        NetTotal: total_amount,
        data: cartItems,
        date: date,
      };

      await fetch("https://cpanel.q-hawk.com/orders/OrderFromE_Commerce", {
        method: "POST",
        body: JSON.stringify(Body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "orderConfirm");
          console.log(Body, "orderBody");
          // localStorage.removeItem()
          navigate("/order/confirm");
          localStorage.removeItem("cart");
        });
    }
  };

  async function profileDetails() {
    await fetch("https://cpanel.q-hawk.com/customer/getspecificCustomer", {
      method: "POST",
      body: JSON.stringify({
        customer_id: intCoustId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "profiledata");
        setName(data.CustomerName);
        setEmail(data.Email);
        setPhone(data.Phone);
        setAddress(data.Address);
        setCity(data.City);
        setRegion(data.State);
        setPin(data.PinCode);
      });
  }

  useEffect(() => {
    profileDetails();
  }, []);
  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.checkout_page}>
          <div className={style.checkout}>
            <div className={style.shipping_address}>
              <h1>Shipping address</h1>
              <div className={style.shipping_head}>
                <label htmlFor="">Full name *</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={style.number}>
                <label htmlFor="">Phone number *</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="">Email address *</label>
                <input
                  type="email"
                  name=""
                  id=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className={style.email}>
                <div className={style.email_address}>
                  <label htmlFor="">Street name and house number *</label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                {/* <div className={style.conf_email}>
                  <label htmlFor="">Confirmation email *</label>
                  <input type="email" name="" id="" />
                </div> */}
              </div>

              <div className={style.places}>
                <div className={style.city}>
                  <label htmlFor="">City *</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className={style.region}>
                  <label htmlFor="">State *</label>
                  <input
                    type="text"
                    name=""
                    value={region}
                    id=""
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </div>
              </div>
              <div className={style.post_code}>
                <label htmlFor="">Pin code</label>
                <input
                  type="number"
                  value={pin}
                  name=""
                  id=""
                  onChange={(e) => setPin(e.target.value)}
                />
              </div>
            </div>
            {/* <div className={style.shipping_method}>
              <div className={style.method_head}>
                <h1>Shipping method</h1>
              </div>
              <div className={style.method_one}>
                <div className={style.one_left}>
                  <input type="radio" name="" id="" />
                  <p>Free shipping</p>
                </div>
                <p>7-30 business days</p>
                <div className={style.one_right}>
                  <p>$0</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className={style.order}>
            <div className={style.ordera_summory}>
              <div className={style.order_head}>
                <h1>Your Order</h1>
              </div>
              <div className={style.subtotal}>
                <div className={style.subtotal_left}>
                  <p>Subtotal</p>
                </div>
                <div className={style.subtotal_right}>
                  <p>Rs.{total_amount}</p>
                </div>
              </div>
              <div className={style.Discount}>
                <div className={style.Discount_left}>
                  <p>Discount</p>
                </div>
                <div className={style.Discount_right}>
                  <p>0.0</p>
                </div>
              </div>
              {/* <div className={style.shippment}>
                <div className={style.shippment_left}>
                  <p>Shippment cost</p>
                </div>
                <div className={style.shippment_right}>
                  <p>0.0</p>
                </div>
              </div> */}
              <div className={style.total}>
                <div className={style.total_left}>
                  <p>Grand total</p>
                </div>
                <div className={style.total_right}>
                  <p>Rs.{total_amount}</p>
                </div>
              </div>
              <div className={style.payment_button}>
                <button onClick={orderConfirm}>Order Now & Pay Later</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
