import React, { useEffect, useState } from "react";
import style from "./Login.module.css";
import Shoe from "../../assets/blue1.jpg";
import { RiFolderUserLine } from "react-icons/ri";
import { TbLockOff } from "react-icons/tb";
import { TbLockOpen } from "react-icons/tb";

import { IconContext } from "react-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Action/User";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const userid = localStorage.getItem("userId");
  // console.log("org id",org_id)
  // console.log("type",typeof(org_id))

  const { authenticated } = useSelector((state) => state.user);
  console.log(authenticated, "auth");

  // useEffect(() => {
  //   if (authenticated === false) {
  //     alert('Login failed. Please check your credentials and try again.');
  //   }else{}
  // }, [authenticated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    dispatch(login(data));
    console.log(email);
    console.log(password);

    // Wait for the login API call to complete and local storage values to be set
    // await new Promise(resolve => setTimeout(resolve, 2000));

    // Navigate to the home page
    // navigate("/");
  };
  const userid = localStorage.getItem("userId");
  useEffect(() => {
    // console.log("user_id",userid)
    if (userid === null || userid === undefined) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className={style.container}>
        <div className={style.left}>
          <img src={Shoe} alt="" />
        </div>
        <div className={style.right}>
          <div className={style.form_border}>
            <h1>Swapna Agencies</h1>
            <p>please enter your details</p>
            <form
              action=""
              className={style.register_form}
              onSubmit={handleSubmit}
            >
              <div className={style.email}>
                <input
                  value={email}
                  type="text"
                  name=""
                  id=""
                  placeholder="UserName"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <IconContext.Provider value={{ color: "#3a71f2" }}>
                  <RiFolderUserLine />
                </IconContext.Provider>
              </div>
              <div className={style.password}>
                <input
                  value={password}
                  type={showPassword ? "text" : "password"}
                  name=""
                  id=""
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <IconContext.Provider value={{ color: "#3a71f2" }}>
                  <div onClick={togglePasswordVisibility}>
                    {showPassword ? <TbLockOpen /> : <TbLockOff />}
                  </div>
                </IconContext.Provider>
              </div>
              <div className={style.reset}>
                {/* <div className={style.remember}>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    onChange={(e) => setRemember(e.target.checked)}
                  />
                  <label htmlFor="checkbox">Remember Me</label>
                </div> */}
                <div className={style.forgot}>
                  <p>Forgot Password?</p>
                </div>
              </div>

              <button type="submit">Login</button>
            </form>
          </div>
          <div className={style.register}>
            <p>Don't have an account yet?</p>
            <Link to="/register">
              <button>Sign Up</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
