import React, { useEffect, useState } from "react";
import style from "./OrderList.module.css";
import NavBar from "../NavBar/NavBar";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const OrderList = () => {
  const [orderList, setOrderList] = useState([]);
  
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setOrderList(prevOrderList => {
      const updatedOrderList = [...prevOrderList];
      updatedOrderList[index].isHovering = true;
      return updatedOrderList;
    });
  };
  
  const handleMouseLeave = (index) => {
    setOrderList(prevOrderList => {
      const updatedOrderList = [...prevOrderList];
      updatedOrderList[index].isHovering = false;
      return updatedOrderList;
    });
  };

  async function OrderList() {
    const org_id = JSON.parse(localStorage.getItem("orgId"));
    const intOrg_id = parseInt(org_id);
    const userId = JSON.parse(localStorage.getItem("userId"));
    const intUser_id = parseInt(userId);
    await fetch("https://cpanel.q-hawk.com/orders/getAllorders", {
      method: "POST",
      body: JSON.stringify({
        organization_id: intOrg_id,
        user_id: intUser_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOrderList(data);
        console.log(data, "ordeelist");
      });
  }

  useEffect(() => {
    OrderList();
  }, []);

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.list_page}>
          <div className={style.order}>
            <h2>Orders</h2>
          </div>
          <div className={style.myorders}>
            {orderList.length > 0 &&
              orderList.map((list, index) => {
                return (
                  <div className={style.order_details} key={index}>
                    <div className={style.myorder_head}>
                      <div className={style.list_number}>
                        <p>Sl.No</p>
                      </div>
                      <div className={style.order_id}>
                        <p>Order No</p>
                      </div>
                      {/* <div className={style.order_name}>
                        <p>Customer Name</p>
                      </div> */}
                      <div className={style.order_date}>
                        <p>Order Date </p>
                      </div>
                      <div className={style.order_status}>
                        <p>Status</p>
                      </div>
                      <div className={style.order_total}>
                        <p>Total</p>
                      </div>
                      <div></div>
                    </div>
                    <div className={style.myorder_bottom}>
                      <div className={style.number} key={index}>
                        <p>{index + 1}</p>
                      </div>
                      <div className={style.id}>
                        <p>{list.OrderId}</p>
                      </div>
                      {/* <div className={style.name}>
                        <p>{list.CustomerName}</p>
                      </div> */}
                      <div className={style.date}>
                        <p>{list.Date} </p>
                      </div>
                      <div className={style.status}>
                        <p>{list.status}</p>
                      </div>
                      <div className={style.total}>
                        <p>Rs.{list.Total}</p>
                      </div>

                      <div
                        className={style.order_icon}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <BsFillArrowUpRightSquareFill
                          onClick={() => {
                            navigate("/myorders", {
                              state: { order_id: list.OrderId },
                            });
                          }}
                        />
                       {list.isHovering && (
            <div className={style.icon_suggestion}>
              Go to order detail
            </div>
          )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
