import React, { useEffect, useState } from "react";
import style from "./NavBar.module.css";
import { FiSearch } from "react-icons/fi";
import { BsPersonCircle } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { BsCartDash } from "react-icons/bs";
import { BiLogOutCircle } from "react-icons/bi";
import { TbBoxSeam } from "react-icons/tb";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { IoMdChatbubbles } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Search_Items } from "../../Action/Search";
import { AiOutlineClose } from "react-icons/ai";
import { SEARCH_DELETE } from "../../Constants/Search";
import { HiShoppingCart } from "react-icons/hi";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [mobileSearch, setMobileSearch] = useState(false);
  const [searchPopUp, setSearchPopUP] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);
  const [chatPopup, setChatPopup] = useState(false);
  const [chatFirstName, setChatFirstName] = useState("");
  const [chatSecondName, setChatSecondName] = useState("");
  const [chatEmail, setChatEmail] = useState("");
  const [chatPhone, setChatPhone] = useState("");

  const cartItems = localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"));

  const dispatch = useDispatch();
  const openProfile = () => {
    open ? setOpen(false) : setOpen(true);
  };
  const openSearch = () => {
    setMobileSearch(true);
    setOpen(false);
  };
  const suggetion = () => {
    // searchPopUp ?setSearchPopUP(false):
    setSearchPopUP(true);
  };
  const popupClose = () => {
    setSearchPopUP(false);
    console.log("popup closed");
  };
  const productSearch = () => {
    setSearchPopUP(true);

    const searchData = searchItem;
    dispatch(Search_Items(searchData));
  };
  const { search } = useSelector((state) => state.search);
  console.log(search, "search list");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("orgId");
    localStorage.removeItem("userId");

    localStorage.removeItem("checkout");
    navigate("/login");
  };
  const chatClick = () => {
    setChatOpen(!chatOpen);
    setChatPopup(true);
  };
  const chatPopupClick = () => {
    setChatOpen(!chatOpen);
    setChatPopup(false);
  };

  const org_id = JSON.parse(localStorage.getItem("orgId"));
  const intOrg_id = parseInt(org_id);

  async function chatCall() {
    await fetch("http://143.110.252.193:8000/teamify/room/firestore/create", {
      method: "POST",
      body: JSON.stringify({
        group_title: "Web",
        chatFirstName,
        group_des: "Web",
        chatPhone,
        sender_user_id: chatPhone,
        sender_user_name: chatFirstName,
        org_id: intOrg_id,
        receiver_user_id: "",
        receiver_user_name: "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "chatres");
      });
  }



  return (
    <div className={style.container}>
      <div className={style.navbar}>
        <div className={style.left}>
          <Link className={style.link} to="/">
            <h4>Swapna Agencies</h4>
          </Link>
        </div>
        <div className={style.center}>
          <div className={style.search}>
            <div className={style.search_text}>
              <input
                type="text"
                name=""
                id=""
                placeholder="Search your brands"
                // onClick={suggetion}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
            <div className={style.icons}>
              {search && search.length > 0 && search !== undefined ? (
                <AiOutlineClose
                  onClick={() => {
                    dispatch({ type: SEARCH_DELETE });
                    setSearchPopUP(false);
                  }}
                />
              ) : (
                <FiSearch onClick={productSearch} />
              )}
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.profile}>
            <div className={style.cartButton}>
              <HiShoppingCart
                onClick={() => {
                  navigate("/cart");
                }}
              />
              {/* <span>{cartItems.length}</span> */}
            </div>

            <p>Profile</p>
            <BsPersonCircle onClick={openProfile} />
          </div>
        </div>

        {open ? (
          <div className={style.drop_menu}>
            <div className={style.drop_item}>
              <div className={style.mobile_search}>
                <p>Search</p>
                <div className={style.mobsearch_icon}>
                  <FiSearch onClick={openSearch} />
                </div>
              </div>
              <div className={style.myprofile}>
                <div className={style.my_icon}>
                  <BsPersonFill />
                </div>
                <div className={style.my_prof}>
                  <Link style={{ textDecoration: "none" }} to="/myprofile">
                    <p>My Profile</p>
                  </Link>
                </div>
              </div>
              <div className={style.myorder}>
                <div className={style.order_icon}>
                  <TbBoxSeam />
                </div>
                <div className={style.order}>
                  <Link style={{ textDecoration: "none" }} to="/orderlist">
                    <p>My Orders</p>
                  </Link>
                </div>
              </div>
              <div className={style.cart}>
                <div className={style.cart_icon}>
                  <BsCartDash />
                </div>
                <div className={style.cart_text}>
                  <Link style={{ textDecoration: "none" }} to="/cart">
                    <p>My Cart</p>
                  </Link>
                </div>
              </div>

              <div className={style.logout}>
                <div className={style.log_icon}>
                  <BiLogOutCircle />
                </div>
                <div className={style.log}>
                  <p onClick={logout}> Logout</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {searchPopUp ? (
        <div className={style.search_popup}>
          {/* <div className={style.popup_close}>
              <MdOutlineCloseFullscreen onClick={popupClose} />
            </div> */}
          <div className={style.popup_center}>
            <div className={style.popup_main}>
              <div className={style.popup_head}>
                {search &&
                  search.length > 0 &&
                  search !== undefined &&
                  search.map((search_item) => {
                    return (
                      <div
                        className={style.popup_text}
                        onClick={() => {
                          navigate("/search/products", {
                            state: { search: search_item },
                          });
                        }}
                      >
                        <p>{search_item.product_name}</p>
                        <FiSearch />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div className={style.chat_icon}>
          {chatOpen ? (
            <IoMdChatbubbles onClick={chatClick} />
          ) : (
            <MdOutlineCloseFullscreen onClick={chatPopupClick} />
          )}
        </div> */}
      {chatPopup ? (
        <div className={style.chat_container}>
          <div className={style.chat_page}>
            <div className={style.chat_head}>
              <h3>Hii,Chat with us</h3>
            </div>
            <div className={style.chat_body}>
              {/* <div className={style.chat_name}> */}
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name=""
                id=""
                onChange={(e) => setChatFirstName(e.target.value)}
              />
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name=""
                id=""
                onChange={(e) => setChatSecondName(e.target.value)}
              />
              {/* </div> */}
              {/* <div className={style.chat_number}> */}
              <label htmlFor="">Email</label>
              <input
                type="email"
                name=""
                id=""
                onChange={(e) => setChatEmail(e.target.value)}
              />
              <label htmlFor="">Phone Number</label>
              <input
                type="number"
                onChange={(e) => setChatPhone(e.target.value)}
              />
              {/* </div> */}
              <div className={style.chat_button}>
                <button onClick={chatCall}>Get Started</button>
              </div>
              <div className={style.chat_box}>
                <textarea name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {mobileSearch ? (
        <div className={style.search_mobile}>
          <div className={style.mobile_fullsearch}>
            <div className={style.search_left}>
              <input type="text" />
            </div>
            <div className={style.search_right}>
              {search && search.length > 0 && search !== undefined ? (
                <AiOutlineClose
                  onClick={() => {
                    dispatch({ type: SEARCH_DELETE });
                  }}
                />
              ) : (
                <FiSearch onClick={productSearch} />
              )}
            </div>
          </div>
          <div className={style.mobsearch_result}>
            {search &&
              search.length > 0 &&
              search !== undefined &&
              search.map((search_item) => {
                return (
                  <div
                    className={style.search_results}
                    onClick={() => {
                      navigate("/search/products", {
                        state: { search: search_item },
                      });
                    }}
                  >
                    <p>{search_item.product_name}</p>
                    <FiSearch />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NavBar;
