import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import style from "./MyProfile.module.css";
const MyProfile = () => {
  const [profData, setProfData] = useState("");

  const coustomerid = JSON.parse(localStorage.getItem("customerId"));
  const intCoustId = parseInt(coustomerid);

  async function profileDetails() {
    await fetch("https://cpanel.q-hawk.com/customer/getspecificCustomer", {
      method: "POST",
      body: JSON.stringify({
        customer_id: intCoustId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "profiledata");
        setProfData(data);
      });
  }

  useEffect(() => {
    profileDetails();
  }, []);

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.profile_page}>
          <div className={style.prof_head}>
            <h1>My Profile</h1>
          </div>

          <div className={style.profile_Name}>
            <div className={style.first_name}>
              <label htmlFor=""> Name</label>
              <input type="text" value={profData.CustomerName}/>
            </div>
            {/* <div className={style.second_name}>
              <label htmlFor="">Last Name</label>
              <input type="text" />
            </div> */}
          </div>
          <div className={style.information}>
            <label htmlFor="">Email</label>
            <input type="email" name="" id="" value={profData.Email}/>
            <label htmlFor="">Contact Number</label>
            <input type="text" name="" id="" value={profData.Phone} />
            <label htmlFor="">Address</label>
            <input type="text" name="" id="" value={profData.Address} />
          </div>
          <div className={style.location}>
            <div className={style.city}>
              <label htmlFor="">City</label>
              <input type="text" name="" id="" value={profData.City} />
            </div>
            <div className={style.state}>
              <label htmlFor="">State</label>
              <input type="text" name="" id="" value={profData.State} />
            </div>
          </div>
          <div className={style.second_location}>
            <div className={style.pincode}>
              <label htmlFor="">Pin Code</label>
              <input type="text" name="" id="" value={profData.PinCode} />
            </div>
            <div className={style.country}>
              <label htmlFor="">Country</label>
              <input type="text" name="" id="" value={profData.Country} />
            </div>
          </div>
          {/* <div className={style.submitt}>
            <button>Save</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
