import React, { useState, useEffect } from "react";
import style from "./ProductSelection.module.css";
import NavBar from "../NavBar/NavBar";
import { GiRunningShoe } from "react-icons/gi";
import { BsBox2Fill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { FaShoppingCart } from "react-icons/fa";

const ProductSelection = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [count, setCount] = useState(0);
  const [caseCollection, setCaseCollection] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  const increment = () => {
    if (count < 100) {
      setCount(Number(count + 1));
    }
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const handleChange = (e) => {
    setCount(e.target.value);
  };

  const location = useLocation();
  const state = location.state;
  console.log(state);
  const selection_product = state.product_selection;

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart"));

    if (cartItems) {
      setCart(cartItems);
    }
  }, []);

  const userid = localStorage.getItem("userId");
  useEffect(() => {
    // console.log("user_id",userid)
    if (userid === null || userid === undefined) {
      navigate("/login");
    }
  }, []);

  function addCart(e) {
    e.preventDefault();

    const productIds = JSON.parse(localStorage.getItem("id"));
    let totalVariantsQuantity = 0;
    if (selectedCategory === "Special") {
      totalVariantsQuantity = selection_product.varients.reduce(
        (total, variant) => total + parseInt(variant.quantity),
        0
      );
    }

    let result;
    let price;

    if (selectedCategory === "Special") {
      result =
        parseInt(selection_product.selling_price) * totalVariantsQuantity;
      price = parseInt(selection_product.selling_price);
    } else {
      result = parseInt(selection_product.price) * parseInt(count);
      price = parseInt(selection_product.price);
    }

    const singleCart = {
      product_id: selection_product.product_id,
      item: selection_product.product_name,
      quan: count,
      size: selection_product.size,
      result: result,
      data: selection_product,
      uo: selection_product.UOM,
      price: price,
      type: selectedCategory,
      varients: selection_product.varients,
      is_packaged_item: selection_product.is_packaged_item,
    };
    let updatedCart = [...cart];
    if (selection_product.is_packaged_item === 1) {
      if (selectedCategory === "Standard") {
        singleCart.varients = [];
        const existingItemIndex = updatedCart.findIndex(
          (item) => item.item === singleCart.item && item.type === "Standard"
        );
        if (existingItemIndex !== -1) {
          if (updatedCart[existingItemIndex].type === "Standard") {
            updatedCart[existingItemIndex].quan += singleCart.quan;
            updatedCart[existingItemIndex].result += singleCart.result;
          }
        } else {
          updatedCart.push(singleCart);
        }
        // updatedCart.push(singleCart);
      } else if (selectedCategory === "Special") {
        // updatedCart.push(singleCart);
        singleCart.quan = 1;
        console.log(updatedCart);

        // started
        // const existingItemIndex = updatedCart.findIndex(
        //   (item) => item.item === singleCart.item
        // );

        // if (existingItemIndex !== -1) {
        //   updatedCart[existingItemIndex].varients.forEach((item1)=>{
        //     singleCart.varients.forEach((item2)=>{
        //       if(item1.product_id===item2.product_id){

        //         let amount =  parseInt(item1.quantity)
        //         amount = parseInt(item1.quantity) + parseInt(item2.quantity)
        //          item1.quantity = amount

        //       }
        //     })
        //   })

        // console.log(updatedCart[existingItemIndex])

        // } else {
        updatedCart.push(singleCart);
        // }

        // finished

        // console.log(updatedCart)
        // console.log(updatedCart[existingItemIndex])
        // console.log(parentIndex)

        // const existingItemIndex = updatedCart[parentIndex].varients.findIndex(
        //   (item) => item.packaged_product_id === productIds
        // );
        // console.log(updatedCart[parentIndex].varients[existingItemIndex])

        // if (existingItemIndex !== -1) {
        //   updatedCart[parentIndex].varients[existingItemIndex].quantity += singleCart.varients[existingItemIndex].quantity;
        // } else {
        // updatedCart.push(singleCart);
        // }
      }
    } else {
      const existingItemIndex = updatedCart.findIndex(
        (item) => item.item === singleCart.item
      );
      if (existingItemIndex !== -1) {
        updatedCart[existingItemIndex].quan += singleCart.quan;
        updatedCart[existingItemIndex].result += singleCart.result;
      } else {
        updatedCart.push(singleCart);
      }
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCount(0);
    window.location.reload();

    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  }

  const openStandard = () => {
    setSelectedCategory("Standard");
  };

  const openSpecial = () => {
    setSelectedCategory("Special");
  };
  console.log(caseCollection);

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.selection_page}>
          <div className={style.product_name}>
            <h2>{selection_product.product_name}</h2>
          </div>
          <div className={style.section_selection}>
            <h3>Add your collection</h3>
          </div>
          <div className={style.selection_container}>
            <div className={style.item_selection}>
              {selection_product.is_packaged_item === 0 ? (
                <div className={style.new_standard}>
                  <div className={style.new_head}>
                    <h2>Select your case catogry</h2>
                  </div>
                  <h3>Standard Case</h3>
                  <div className={style.standard_case}>
                    <div className={style.case_name}>
                      <p>{selection_product.product_name}</p>
                    </div>
                    <div className={style.case_value}>
                      {selection_product.item_contains * count}
                    </div>
                    <div className={style.standard_quantity}>
                      <div className={style.standardquantity_name}>
                        <p className={style.webQuantity}>
                          Select your quantity
                        </p>
                        <p className={style.selectQuantity}>Quantity</p>
                      </div>
                      <div className={style.standardquantity_add}>
                        <button onClick={decrement}>-</button>
                        <input
                          type="text"
                          name=""
                          id=""
                          value={count}
                          onChange={handleChange}
                        />
                        <button onClick={increment}>+</button>
                      </div>
                    </div>
                  </div>
                  <div className={style.section_bottom}>
                    <div className={style.nw_cancel}>
                      <button>Cancel</button>
                    </div>
                    <div className={style.bulkcart_new}>
                      <button onClick={addCart}>Add to cart</button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {selection_product.is_packaged_item === 1 ? (
                <div className={style.selection_catogory}>
                  <div className={style.catogory_drop}>
                    <h3 className={style.web_catogory}>
                      Please select your case catogory
                    </h3>
                    <h3 className={style.mob_catogory}>Select catogory</h3>
                    <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      <option value="Standard">Standard Case</option>
                      <option value="Special">Special Case</option>
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {selectedCategory === "Standard" ? (
              <div className={style.bulk_selection_page}>
                <div className={style.bulk_heed}></div>
                <h3>Standard Case</h3>
                <div className={style.standard_case}>
                  <div className={style.case_name}>
                    <p>{selection_product.product_name}</p>
                  </div>
                  <div className={style.case_value}>
                    {selection_product.item_contains * count}
                  </div>
                  <div className={style.standard_quantity}>
                    <div className={style.standardquantity_name}>
                      <p className={style.webQuantity}>Select your quantity</p>
                      <p className={style.selectQuantity}>Quantity</p>
                    </div>
                    <div className={style.standardquantity_add}>
                      <button onClick={decrement}>-</button>
                      <input
                        type="text"
                        name=""
                        id=""
                        value={count}
                        onChange={handleChange}
                      />
                      <button onClick={increment}>+</button>
                    </div>
                  </div>
                </div>
                <div className={style.section_bottom}>
                  <div className={style.nw_cancel}>
                    <button>Cancel</button>
                  </div>
                  <div className={style.bulkcart_new}>
                    <button onClick={addCart}>Add to cart</button>
                  </div>
                </div>
              </div>
            ) : selectedCategory === "Special" ? (
              <div className={style.bulk_selection}>
                <div className={style.special_page}>
                  <div className={style.top_head}>
                    <div className={style.special_headname}>
                      <h3>Special Case</h3>
                    </div>

                    <div className={style.specialcase_name}>
                      <p>{selection_product.product_name}</p>
                    </div>
                  </div>
                  {selection_product.varients.length > 0 &&
                    selection_product.varients.map((productVarients) => {
                      return (
                        <div className={style.special_case}>
                          <div className={style.case_size}>
                            <div className={style.size_head}>
                              <p className={style.webSize}>Size</p>
                              <p className={style.mobSize}>Size</p>
                            </div>
                            <div className={style.size_value}>
                              <p>{productVarients.size}</p>
                            </div>
                          </div>
                          <div className={style.case_input}>
                            <div className={style.input_head}>
                              <p className={style.webQuantity}>
                                Enter the product quantity
                              </p>
                              <p className={style.selectQuantity}>Quantity</p>
                            </div>
                            <div className={style.input_value}>
                              <input
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => {
                                  setCaseCollection(e.target.value);
                                  localStorage.setItem(
                                    "id",
                                    JSON.stringify(productVarients.varient_id)
                                  );
                                  const productId = JSON.parse(
                                    localStorage.getItem("id")
                                  );
                                  if (
                                    productId === productVarients.varient_id
                                  ) {
                                    console.log("here");
                                    productVarients.quantity = e.target.value;
                                  }
                                  // setSelectedVariants(updatedVariants);
                                }}
                                onClick={() => {
                                  const updatedVariants = [
                                    ...selectedVariants,
                                    {
                                      variant_id: productVarients.varient_id,
                                      quantity: caseCollection,
                                    },
                                  ];
                                  setSelectedVariants(updatedVariants);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className={style.section_bottom}>
                    <div className={style.bulk_cancel}>
                      <button>Cancel</button>
                    </div>
                    <div className={style.bulkcart_button}>
                      <button onClick={addCart}>Add to cart</button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {showPopup && (
            <div className={style.popup_page}>
              <div className={style.popup_sucess}>
                <TiTick />
              </div>

              <div className={style.popup_cart}>
                <div className={style.cart_title}>
                  <p> Product added to cart!</p>
                </div>
                <div className={style.cart_icon}>
                  <Link style={{ textDecoration: "none" }} to="/cart">
                    <FaShoppingCart />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSelection;
