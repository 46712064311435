import React, { useEffect } from "react";
import style from "./OrderDetail.module.css";
import NavBar from "../NavBar/NavBar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
const OrderDetail = () => {
  const [OrderDetail, setOrderDetail] = useState({});

  const location = useLocation();
  const state = location.state;
  console.log(state);
  const productOrder_id = state.order_id;
  console.log(productOrder_id);

  async function orderDetail() {
    await fetch("https://cpanel.q-hawk.com/orders/getOrder", {
      method: "POST",
      body: JSON.stringify({
        OrderId: productOrder_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orderdetail");
        setOrderDetail(data);
      });
  }

  useEffect(() => {
    orderDetail();
  }, []);

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.detail_page}>
          <div className={style.detail_head}>
            <h1>My Orders</h1>
          </div>

          <div className={style.order_one}>
            <div className={style.one_head}>
              <div className={style.head_left}>
                <h1>Ordered Items</h1>
              </div>
              <div className={style.head_right}>
                <p>Date :{OrderDetail.OrderDate}</p>
              </div>
            </div>
            <div className={style.orders}>
              {OrderDetail.OrderItems !== undefined &&
                OrderDetail.OrderItems !== null &&
                OrderDetail.OrderItems.length > 0 &&
                OrderDetail.OrderItems.map((detail, index) => {
                  return (
                    <div className={style.item_one}>
                      <div className={style.item_number}>
                        <div className={style.number_up}>
                          <p>Sl.No</p>
                        </div>
                        <div className={style.number_bottom}>
                          <p>{index + 1}</p>
                        </div>
                      </div>

                      <div className={style.item_name}>
                        <div className={style.name_up}>
                          <p> Item Name</p>
                        </div>
                        <div className={style.name_bottom}>
                          <p>{detail.item}</p>
                        </div>
                      </div>
                      <div className={style.item_quantity}>
                        <div className={style.quantity_up}>
                          <p>Quantity</p>
                        </div>
                        <div className={style.quantity_bottom}>
                          <p>{detail.quan}</p>
                        </div>
                      </div>
                      <div className={style.item_price}>
                        <div className={style.price_up}>
                          <p>Price</p>
                        </div>
                        <div className={style.price_bottom}>
                          <p>Rs.{detail.price}</p>
                        </div>
                      </div>
                      <div className={style.item_tottal}>
                        <div className={style.tottal_up}>
                          <p>Total</p>
                        </div>
                        <div className={style.tottal_bottom}>
                          <p>Rs.{detail.result}</p>
                        </div>
                      </div>
                      {/* <div className={style.item_status}>
                        <div className={style.status_up}>
                          <p>Order Status</p>
                        </div>
                        <div className={style.status_bottom}>
                          <p>Success</p>
                        </div>
                      </div> */}
                    </div>
                  );
                })}
            </div>
            <div className={style.price}>
              <div className={style.subtotal}>
                <div className={style.subtotal_left}>
                  <p>Subtotal</p>
                </div>
                <div className={style.subtotal_right}>
                  <p>Rs.{parseFloat(OrderDetail.Total).toFixed(3)}</p>
                </div>
              </div>
              {/* <div className={style.shipping}>
                <div className={style.shipping_left}>
                  <p>Shipping</p>
                </div>
                <div className={style.shipping_right}>
                  <p>$3</p>
                </div>
              </div> */}
              <div className={style.tax}>
                <div className={style.tax_left}>
                  <p>Tax</p>
                </div>
                <div className={style.tax_right}>
                  <p>Rs.{parseFloat(OrderDetail.Tax).toFixed(3)}</p>
                </div>
              </div>
              <div className={style.grant_total}>
                <div className={style.total_left}>
                  <p>Grant Total</p>
                </div>
                <div className={style.total_right}>
                  <p>Rs : {(parseFloat(OrderDetail.Total) + parseFloat(OrderDetail.Tax)).toFixed(3)} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
