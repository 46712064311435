import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../Constants/User";

export const UserReducer = (state = { user: {} }, action) => {        //everything is present on state  , all the cases are present in user
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        authenticated: false,
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        authenticated: true,
        user: action.payload,
      };
    case LOGIN_FAIL:
      return {
        loading: false,
        authenticated: false,
        error: action.payload,
      };
    default:
        return{
            ...state
        }
  }
};
