import React, { useState, useEffect } from "react";
import style from "./SubLevel.module.css";
import NavBar from "../NavBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";

const SubLevel = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const product_sublevels = state.sublevel;
  const [productSublevel, setProductSublevel] = useState(product_sublevels);
  const navigate = useNavigate();

  // useEffect(() => {
   
   
  // }, [])
  

  const org_id = JSON.parse(localStorage.getItem("orgId"));
    const intOrg_id = parseInt(org_id);
  async function fetchProducts(level_id) {
    await fetch(
      "https://cpanel.q-hawk.com/product/GetProductLevelWithNextSubLevel",
      {
        method: "POST",
        body: JSON.stringify({
          organization_id: intOrg_id,
          product_level_id: level_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "catogry");

        if (data === 0) {
          endProducts(level_id);
          console.log("end product", data);
        } else {
          console.log("else", data);
          setProductSublevel(data);
        }
      });
  }

  async function endProducts(new_level) {
    await fetch(
      "https://cpanel.q-hawk.com/product/GetAllProductForAdminWithFilter",
      {
        method: "POST",
        body: JSON.stringify({
          organization_id: intOrg_id,
          product_level_id: new_level,
          lower_limit: 1,
          page_size: 10,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "endproduct");
        navigate("/products", { state: { filterProduct: data } });
      });
  }

  return (
    <div>
      <NavBar />
      <div className={style.container}>
        <div className={style.search_page}>
          <div className={style.product_firstrow}>
            {productSublevel.map((new_sublevel) => {
              return (
                <div
                  className={style.product_card}
                  onClick={() => {
                    fetchProducts(new_sublevel.product_level_id);
                  }}
                >
                  <div className={style.product_image}>
                    <img src={new_sublevel.image_url} alt="" />
                  </div>
                  <div className={style.card_body}>
                    <div className={style.product_description}>
                      <div className={style.product_name}>
                        <p>{new_sublevel.level_name}</p>
                      </div>
                     
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubLevel;
